
import moment from 'moment';

const coin = (value) => Math.floor(value * 100) / 100;
const bcoin = (value) => Math.floor(value * 100000) / 100000;


const fetchLatest = (cb, start = '2018-11-27', end = '2034-12-31') =>
    fetch(`https://api.coindesk.com/v1/bpi/historical/close.json?start=${start}&end=${end}`)
        .then(res => res.json())
        .then(cb);


const calculate = (priceToday, priceData, amount, yearsAgo, dayOfTheMonth) => {

     const today = moment();
     const start = moment().add((0 - yearsAgo), 'year');
     start.date(dayOfTheMonth);

     const current = moment(start);

     let accumulatedBtc = 0;
     let months = 0;
     let purchases = [];

     for (let i = 0; current < today; i++) {
          const buyDate = current.format('YYYY-MM-DD');
          const priceDataOnBuyDate = priceData.bpi[buyDate];
          const boughtBtc = amount / priceDataOnBuyDate;

          purchases.push(`Bought ${bcoin(boughtBtc)} on ${buyDate} at ${coin(priceDataOnBuyDate)} USD/BTC`);

          accumulatedBtc += boughtBtc;
          months += 1;
          current.add(1, 'month');
     }

     const bitcoinValue = accumulatedBtc * priceToday;

     const fiatValue = months * amount;

     return {
          start: start.format('YYYY-MM-DD'),
          today: today.format('YYYY-MM-DD'),
          bitcoinValue,
          fiatValue,
          purchases,
          accumulatedBtc
     }
};



export { calculate, coin, bcoin, fetchLatest }