import React, { Component } from 'react';
import price from './pricedata/2010-07-17-to-2018-11-26';

import {calculate, coin, bcoin, fetchLatest} from "./calculation";

class App extends Component {

  state = {
    amount: 100,
    dayOfTheMonth: 5,
    yearsAgo: 4,
    priceData: price,
    priceToday: null,
    priceRecent: false,
  };

  componentDidMount() {

    fetch('https://api.coindesk.com/v1/bpi/currentprice.json')
        .then(res => res.json())
        .then((currentPrice) => {
          this.setState({
            priceToday: currentPrice.bpi.USD.rate_float
          })
        });

    // Adds recent price data to statically preloaded data.
    fetchLatest((latest) => {
      this.setState({
        priceData: {
          ...this.state.priceData,
          bpi: {
            ...this.state.priceData.bpi,
            ...latest.bpi
          }
        },
        priceRecent: true
      });
    });
  }

  render() {

    const { priceRecent, priceToday, priceData, amount, yearsAgo, dayOfTheMonth } = this.state;

    if (!priceRecent || !priceToday) { return null; }

    const calculation = calculate(priceToday, priceData, amount, yearsAgo, dayOfTheMonth);

    const PL = calculation.bitcoinValue >= calculation.fiatValue ? 'profit' : 'loss';

    return (
      <div className="App">

        <header>
          <h1><i className="icon heart"/> Bitcoin savings</h1>
          <p>Should you save in dollars or bitcoins?</p>
        </header>


        <section className="form container calculator with-title">
          <h2 className="title">Calculator</h2>


            <label htmlFor="inline_field" className="sentence">If you had bought</label>
            <input className="input sentence" style={{ width: 130, textAlign: 'center' }}
                type="number" value={this.state.amount} onChange={(e) => this.setState({ amount: e.target.value })} />
          <span className="sentence">USD worth of bitcoin</span><br/><br/>

          &nbsp;&nbsp;&nbsp;&nbsp; every month on the
          <select onChange={(e) => this.setState({ dayOfTheMonth: e.target.value })} value={this.state.dayOfTheMonth}>
            <option value="1">1st</option>
            <option value="2">2nd</option>
            <option value="3">3rd</option>
            <option value="4">4th</option>
            <option value="5">5th</option>
            <option value="6">6th</option>
            <option value="7">7th</option>
            <option value="8">8th</option>
            <option value="9">9th</option>
            <option value="10">10th</option>
            <option value="11">11th</option>
            <option value="12">12th</option>
            <option value="13">13th</option>
            <option value="14">14th</option>
            <option value="15">15th</option>
            <option value="16">16th</option>
            <option value="17">17th</option>
            <option value="18">18th</option>
            <option value="19">19th</option>
            <option value="20">20th</option>
            <option value="21">21st</option>
            <option value="22">22nd</option>
            <option value="23">23rd</option>
            <option value="24">24th</option>
            <option value="25">25th</option>
            <option value="26">26th</option>
            <option value="27">27th</option>
            <option value="28">28th</option>
            {/*<option value="29">29th</option>*/}
            {/*<option value="30">30th</option>*/}
            {/*<option value="31">31st</option>*/}
          </select>



          <br/><br/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; and started
          <select onChange={(e) => this.setState({ yearsAgo: e.target.value })} value={this.state.yearsAgo}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
          </select>
          years ago
          <br/><br/>

          Your bitcoin would be worth <span className={`btc price ${PL}`}>${coin(calculation.bitcoinValue)} USD</span> today
          <br/><br/>
          or you would have <span className={`fiat price ${PL}`}>${coin(calculation.fiatValue)} USD</span> if you didn't.

        </section>

        <br/><br/>

        <section className="form container with-title">
          <h2 className="title">Your purchase history</h2>
          <br/>
          <p>You would have bought at these exchange rates:</p>
          <br/>
          {calculation.purchases.map((purchase,i) => (
              <div key={i}>{purchase}</div>
          ))}
          <br/>

          <div style={{ textAlign: 'center'}}>
          You would have accumulated<br/>
          a total of <span className="btc">{bcoin(calculation.accumulatedBtc)}</span> bitcoin.
          </div>
        </section>

        <br/><br/>



        <section className="balloon container" style={{ boxShadow: 'none' }}>
          <div className="messages">
            <div className="message -right">
              <div className="balloon from-right">
                <p>Total circulation will be 21,000,000 coins. It'll be distributed
                  to network nodes when they make blocks, with the amount cut in half
                  every 4 years.<br/>
                  <br/>
                  first 4 years: 10,500,000 coins<br/>
                  next 4 years: 5,250,000 coins<br/>
                  next 4 years: 2,625,000 coins<br/>
                  next 4 years: 1,312,500 coins<br/>
                  etc...</p>
              </div>
              <i className="bcrikko"></i>
            </div>
            <div style={{ textAlign: 'right', marginRight: '-30px'}}>
              <a href="http://www.metzdowd.com/pipermail/cryptography/2009-January/014994.html" target="_blank">
                Satoshi
              </a>
            </div>
          </div>
        </section>

        <br/><br/>
        <div style={{ color: 'silver', textAlign: 'center', fontSize: '50%' }}>
          <a href="mailto:fabian@bitcoinriddle.com">Contact</a> | Data source <span style={{ textTransform: 'lowercase' }}>P</span>owered by <a href="https://www.coindesk.com/price/" target="_blank" style={{ display: 'inline' }}>CoinDesk</a>
        </div>
      </div>
    );
  }
}

export default App;
